import ModalBase from '../../js/components/modal/modal-base'

/**
 * La modal de première connexion
 */
export default class ModalPremiereConnexion extends ModalBase {

  /**
   * Initialise une nouvelle instance de type {@type ModalPremiereConnexion}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir au clic "".
   * @override
   */
  _onAccept() {
    this._closeModal();
  }

  /**
   * Action pour réagir au clic "".
   * @override
   */
  _onDismiss() {
    this._closeModal();
  }
}
