import { TagCommanderManager } from "../../js/services/tag-commander-manager";
import ParcoursService from "./parcours-service";
import {
  FERMETURE_PARCOURS,
  EXIT_PARCOURS,
  CLICK_LANCER_PARCOURS,
  PARCOURS_GUIDE,
  PAGE_CLICK_GESTION_DE_VOTRE_ENTREPRISE,
  CLICK_GESTION_DE_VOTRE_ENTREPRISE,
  PAGE_FERMETURE_DU_PARCOURS_GUIDE,
  CLICK_REPRENDRE_LE_TUTORIEL,
  PAGE_CLICK_VOS_DONNEES_ENERGETIQUES,
  CLICK_VOS_DONNEES_ENERGETIQUES,
  PAGE_CLICK_VOS_DOCUMENTS,
  CLICK_VOS_DOCUMENTS,
  PAGE_CLICK_VOS_PROJETS_RACCORDEMENT,
  CLICK_VOS_PROJETS_RACCORDEMENT,
  PAGE_CLICK_GESTION_DE_COMPTE,
  CLICK_GESTION_DE_COMPTE,
  PAGE_FONCTIONNALITES,
  PAGE_GUIDES_UTILISATEURS,
  PAGE_FIN_PARCOURS,
  CLICK_FIN_PARCOURS,
} from "../../js/tag-plan";
import { neufsneufSettings } from "../../js/services/settings-loader";

require("../require");
require("./main.js");
require("./page.scss");
require("./parcours-guide.scss");

// Parcours didactique

const backdrop = document.getElementById("backdrop");

const accueil = document.getElementById("accueil");

const arrow = document.getElementById("arrow");
const _textArrow = document.getElementById("text-arrow");
const _closeButton = document.getElementById("close-button");
const _pageClose = document.getElementById("close");
const menu = document.getElementById("menu");
const encartsAccueil = document.getElementById("encarts-accueil");
const encartTutoriels = document.getElementById("encart-tutoriel");
const background = document.getElementById("background");
const encartCompteurs = document.getElementById("encart-vos-compteurs");

const textTutoriels = encartTutoriels.querySelector("p");
const iconTutoriels = encartTutoriels.querySelector("lnc-icon");
const buttonTutoriels = encartTutoriels.querySelector("a");
const iconButtonTutoriels = buttonTutoriels.querySelector("lnc-icon");

let numMenu = 0;
let saveStep;

let PAUSE;

let header;

let highlightElement;

let lncNavbar;
let lncNavbarItem = [];

const etapes = [];

let mobileStep;

let profilButton;
let profilOverlay;

const parcoursService = new ParcoursService({
  encartTutoriels,
  textTutoriels,
  buttonTutoriels,
  iconButtonTutoriels,
  iconTutoriels,
  backdrop,
  arrow,
  _textArrow,
  _closeButton,
});

// Debut du parcours didactique
export function onClickParcours() {
  sendTagClickParcours();

  startParcours();
}

export function startParcours() {
  setElements();
  setFrame();

  launchStep1();
}

function setFrame() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  document.body.classList.add("no-scroll");
  document.body.classList.add("dismiss-pointer");

  header.classList.add("filter-blur");
  accueil.classList.add("filter-blur");

  backdrop.classList.remove("hidden");
  mobileStep.classList.remove("hidden");

  _closeButton.classList.remove("hidden");

  window.addEventListener("resize", onResizeWindowBackdropAction);
}

function unsetFrame() {
  document.body.classList.remove("no-scroll");
  document.body.classList.remove("dismiss-pointer");

  accueil.classList.remove("filter-blur");
  header.classList.remove("filter-blur");

  backdrop.classList.add("hidden");
  mobileStep.classList.add("hidden");

  _closeButton.classList.add("hidden");

  window.removeEventListener("resize", onResizeWindowBackdropAction);
}

function setElements() {
  PAUSE = false;

  lncNavbar = document.getElementById("lnc-navbar");

  header = document.getElementsByTagName("header")[0];
  lncNavbarItem = document.getElementsByTagName("lnc-nav");
  mobileStep = document.getElementById("mobile-step");

  profilButton = document.querySelector("header lnc-header-menu-entreprise");

  etapes.push(document.getElementById("etape-commune"));

  for (let i = 1; i <= 14; i++) {
    etapes.push(document.getElementById("etape-" + i));
  }
}

/**
 * Ètapes du parcours didactique
 */

function launchStep1() {
  saveStep = 1;

  setDisplayStep1();

  sendTagPageEtapeParcours();
}

function setDisplayStep1() {
  etapes[1].classList.remove("hidden");

  highlighBackdrop(lncNavbar);

  const elementNav = document.querySelector("#lnc-navbar li:nth-of-type(2) lnc-nav ul:nth-of-type(2)");
  parcoursService._moveCloseButton("step_clicknav_and_header", elementNav);

  setTimeout(() => {
    if (!PAUSE) {
      unsetDisplayStep1();
      launchStep2();
    }
  }, 2000);
}

function unsetDisplayStep1() {
  etapes[1].classList.add("hidden");

  highlighBackdrop();
}

function launchStep2() {
  saveStep = 2;
  setDisplayStep2();

  sendTagPageEtapeParcours();
}

function setDisplayStep2() {
  menu.innerHTML = '<b>Cliquez sur <br class="tablet-only">"Gestion de votre entreprise"</b>';

  parcoursService._moveCloseButton("step_clicknav_and_header");

  arrow.classList.add("tablet:lnc-grid-col-1-3", "desktop:lnc-grid-col-2-5");
  _textArrow.classList.add("tablet:lnc-grid-col-1-6", "desktop:lnc-grid-col-2-9");

  etapes[0].classList.remove("hidden");
  lncNavbarItem[1].classList.add("allow-pointer");

  highlighBackdrop(lncNavbar);

  lncNavbarItem[1].addEventListener("click", onClickGoToStep3);
}

function unsetDisplayStep2() {
  arrow.classList.remove("tablet:lnc-grid-col-1-3", "desktop:lnc-grid-col-2-5");
  _textArrow.classList.remove("tablet:lnc-grid-col-1-6", "desktop:lnc-grid-col-2-9");

  etapes[0].classList.add("hidden");
  lncNavbarItem[1].classList.remove("allow-pointer");

  highlighBackdrop();

  lncNavbarItem[1].removeEventListener("click", onClickGoToStep3);
}

function onClickGoToStep3() {
  unsetDisplayStep2();
  launchStep3();
}

function launchStep3() {
  saveStep = 3;
  setDisplayStep3();

  sendTagClickEtapeParcours();
}

function setDisplayStep3() {
  if (!neufsneufSettings.feature_flipping.gestion_autorisation) {
    document.querySelector("#gestion_autorisation").style.display = "none";
  }

  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  backdrop.classList.add("shadow-effect", "backdrop-menu-radius");
  etapes[3].classList.remove("hidden");

  highlighBackdrop(lncNavbarItem[1]);

  document.getElementById("button-to-step4").addEventListener("click", onClickGoToStep4);
}

function unsetDisplayStep3() {
  backdrop.classList.remove("shadow-effect", "backdrop-menu-radius");
  etapes[3].classList.add("hidden");

  highlighBackdrop();

  document.getElementById("button-to-step4").removeEventListener("click", onClickGoToStep4);
}

function onClickGoToStep4() {
  unsetDisplayStep3();
  launchStep4();
}

function launchStep4() {
  saveStep = 4;
  setDisplayStep4();

  sendTagPageEtapeParcours();
}

function setDisplayStep4() {
  menu.innerHTML = '<b>Cliquez maintenant sur <br class="tablet-only">"Vos données énergétiques"</b>';

  parcoursService._moveCloseButton("step_clicknav_and_header");

  document.querySelector("#rubrique-menu").classList.add("hidden");

  backdrop.classList.add("shadow-effect");
  arrow.classList.add("tablet:lnc-grid-col-3-4", "desktop:lnc-grid-col-4-6");
  _textArrow.classList.add("desktop:lnc-grid-col-2-10", "tablet:lnc-grid-col-1-6");
  lncNavbarItem[2].classList.add("allow-pointer");

  etapes[0].classList.remove("hidden");

  highlighBackdrop(lncNavbar);

  lncNavbarItem[2].addEventListener("click", onClickGoToStep5);
}

function unsetDisplayStep4() {
  document.querySelector("#rubrique-menu").classList.remove("hidden");

  backdrop.classList.remove("shadow-effect");
  arrow.classList.remove("tablet:lnc-grid-col-3-4", "desktop:lnc-grid-col-4-6");
  _textArrow.classList.remove("desktop:lnc-grid-col-2-10", "tablet:lnc-grid-col-1-6");
  lncNavbarItem[2].classList.remove("allow-pointer");

  etapes[0].classList.add("hidden");

  highlighBackdrop();

  lncNavbarItem[2].removeEventListener("click", onClickGoToStep5);
}

function onClickGoToStep5() {
  unsetDisplayStep4();
  launchStep5();
}

function launchStep5() {
  saveStep = 5;
  setDisplayStep5();

  sendTagClickEtapeParcours();
}

function setDisplayStep5() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  backdrop.classList.add("shadow-effect", "backdrop-menu-radius");

  etapes[5].classList.remove("hidden");

  highlighBackdrop(lncNavbarItem[2]);

  document.getElementById("button-to-step6").addEventListener("click", onClickGoToStep6);
}

function unsetDisplayStep5() {
  backdrop.classList.remove("shadow-effect", "backdrop-menu-radius");

  etapes[5].classList.add("hidden");

  highlighBackdrop();

  document.getElementById("button-to-step6").removeEventListener("click", onClickGoToStep6);
}

function onClickGoToStep6() {
  unsetDisplayStep5();
  launchStep6();
}

function launchStep6() {
  saveStep = 6;
  setDisplayStep6();

  sendTagPageEtapeParcours();
}

function setDisplayStep6() {
  menu.innerHTML = '<b>Cliquez maintenant sur <br class="tablet-only">"Vos documents"</b>';

  parcoursService._moveCloseButton("step_clicknav_and_header");

  document.querySelector("#rubrique-menu").classList.add("hidden");

  backdrop.classList.add("shadow-effect");
  arrow.classList.add("tablet:lnc-grid-col-5-7", "desktop:lnc-grid-col-7-9");
  _textArrow.classList.add("tablet:lnc-grid-col-2-6", "desktop:lnc-grid-col-3-9");
  lncNavbarItem[3].classList.add("allow-pointer");

  highlighBackdrop(lncNavbar);

  etapes[0].classList.remove("hidden");

  lncNavbarItem[3].addEventListener("click", onClickGoToStep7);
}

function unsetDisplayStep6() {
  document.querySelector("#rubrique-menu").classList.remove("hidden");

  backdrop.classList.remove("shadow-effect");
  arrow.classList.remove("tablet:lnc-grid-col-5-7", "desktop:lnc-grid-col-7-9");
  _textArrow.classList.remove("tablet:lnc-grid-col-2-6", "desktop:lnc-grid-col-3-9");
  lncNavbarItem[3].classList.remove("allow-pointer");

  etapes[0].classList.add("hidden");

  highlighBackdrop();

  lncNavbarItem[3].removeEventListener("click", onClickGoToStep7);
}

function onClickGoToStep7() {
  unsetDisplayStep6();
  launchStep7();
}

function launchStep7() {
  saveStep = 7;
  setDisplayStep7();

  sendTagClickEtapeParcours();
}

function setDisplayStep7() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  backdrop.classList.add("shadow-effect", "backdrop-menu-radius");

  etapes[7].classList.remove("hidden");

  highlighBackdrop(lncNavbarItem[3]);

  document.getElementById("button-to-step8").addEventListener("click", onClickGoToStep8);
}

function unsetDisplayStep7() {
  backdrop.classList.remove("shadow-effect", "backdrop-menu-radius");

  etapes[7].classList.add("hidden");

  highlighBackdrop();

  document.getElementById("button-to-step8").addEventListener("click", onClickGoToStep8);
}

function onClickGoToStep8() {
  unsetDisplayStep7();
  launchStep8();
}

function launchStep8() {
  saveStep = 8;
  setDisplayStep8();

  sendTagPageEtapeParcours();
}

function setDisplayStep8() {
  menu.innerHTML = '<b>Cliquez maintenant sur <br class="tablet-only">“Votre projet raccordement”</b>';

  parcoursService._moveCloseButton("step_clicknav_and_header");

  document.querySelector("#rubrique-menu").classList.add("hidden");

  backdrop.classList.add("shadow-effect");

  lncNavbarItem[4].classList.add("allow-pointer");

  arrow.classList.add("tablet:lnc-grid-col-6-8", "desktop:lnc-grid-col-10-12", "reversed-arrow");
  _textArrow.classList.add("tablet:lnc-grid-col-3-8", "desktop:lnc-grid-col-4-12");

  etapes[0].classList.remove("hidden");

  highlighBackdrop(lncNavbar);

  lncNavbarItem[4].addEventListener("click", onClickGoToStep9);
}

function unsetDisplayStep8() {
  menu.innerHTML = "";

  document.querySelector("#rubrique-menu").classList.remove("hidden");

  backdrop.classList.remove("shadow-effect");

  lncNavbarItem[4].classList.remove("allow-pointer");

  arrow.classList.remove("tablet:lnc-grid-col-6-8", "desktop:lnc-grid-col-10-12", "reversed-arrow");
  _textArrow.classList.remove("tablet:lnc-grid-col-3-8", "desktop:lnc-grid-col-4-12");

  etapes[0].classList.add("hidden");

  highlighBackdrop();

  lncNavbarItem[4].removeEventListener("click", onClickGoToStep9);
}

function onClickGoToStep9() {
  unsetDisplayStep8();
  launchStep9();
}

function launchStep9() {
  saveStep = 9;
  setDisplayStep9();

  sendTagClickEtapeParcours();
}

function setDisplayStep9() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  document.querySelector("#rubrique-menu").classList.add("hidden");

  backdrop.classList.add("shadow-effect", "backdrop-menu-radius");

  etapes[9].classList.remove("hidden");

  highlighBackdrop(lncNavbarItem[4]);

  document.getElementById("button-to-step10").addEventListener("click", onClickGoToStep10);
}

function unsetDisplayStep9() {
  document.querySelector("#rubrique-menu").classList.remove("hidden");

  backdrop.classList.remove("shadow-effect", "backdrop-menu-radius");

  etapes[9].classList.add("hidden");

  highlighBackdrop();

  document.getElementById("button-to-step10").removeEventListener("click", onClickGoToStep10);
}

function onClickGoToStep10() {
  unsetDisplayStep9();
  launchStep10();
}

function launchStep10() {
  saveStep = 10;
  setDisplayStep10();

  sendTagPageEtapeParcours();
}

function setDisplayStep10() {
  menu.innerHTML = '<b>Cliquez maintenant sur <br class="tablet-only">la gestion de votre compte</b>';

  document.querySelector("#rubrique-menu").classList.add("hidden");

  parcoursService._moveCloseButton("step_clicknav_and_header");

  backdrop.classList.add("shadow-effect");
  profilButton.classList.add("allow-pointer");

  arrow.classList.add("tablet:lnc-grid-col-6-8", "desktop:lnc-grid-col-10-12", "reversed-arrow");

  _textArrow.classList.add("tablet:lnc-grid-col-3-8", "desktop:lnc-grid-col-4-12");

  header.classList.remove("filter-blur");

  etapes[0].classList.add("negative-height");
  etapes[0].classList.remove("hidden");

  highlighBackdrop(profilButton);

  profilButton.addEventListener("click", onClickGoToStep11);
}

function unsetDisplayStep10() {
  document.querySelector("#rubrique-menu").classList.remove("hidden");

  backdrop.classList.remove("shadow-effect");

  profilButton.classList.remove("allow-pointer");

  arrow.classList.remove("tablet:lnc-grid-col-6-8", "desktop:lnc-grid-col-10-12", "reversed-arrow");

  _textArrow.classList.remove("tablet:lnc-grid-col-3-8", "desktop:lnc-grid-col-4-12");

  header.classList.add("filter-blur");

  etapes[0].classList.remove("negative-height");
  etapes[0].classList.add("hidden");

  highlighBackdrop();

  profilButton.removeEventListener("click", onClickGoToStep11);
}

function onClickGoToStep11() {
  unsetDisplayStep10();
  launchStep11();
}

function launchStep11() {
  saveStep = 11;
  setDisplayStep11();

  sendTagClickEtapeParcours();
}

function setDisplayStep11() {
  parcoursService._moveCloseButton("step11", profilButton.getBoundingClientRect());

  header.classList.remove("filter-blur");

  etapes[11].classList.remove("hidden");

  highlighBackdrop(profilButton);

  profilOverlay = document.getElementsByClassName("cdk-overlay-pane")[0];

  document.getElementById("button-to-step12").addEventListener("click", onClickGoToStep12);
}

function unsetDisplayStep11() {
  header.classList.add("filter-blur");

  etapes[11].classList.add("hidden");

  if (profilOverlay && profilOverlay.firstElementChild) {
    profilOverlay.firstElementChild.classList.remove("dropdown-open");
  }

  highlighBackdrop();

  document.getElementById("button-to-step12").removeEventListener("click", onClickGoToStep12);
}

function onClickGoToStep12() {
  unsetDisplayStep11();
  launchStep12();
}

function launchStep12() {
  saveStep = 12;
  setDisplayStep12();

  sendTagPageEtapeParcours();
}

function setDisplayStep12() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  accueil.classList.remove("filter-blur");

  backdrop.classList.add("shadow-effect", "backdrop-tutoriels-radius");

  // Afficher l'étape 12 après l'animation de transition
  setTimeout(function () {
    if (!PAUSE) {
      etapes[12].classList.remove("hidden");
    }
  }, 3000);

  setTimeout(function () {
    if (!PAUSE) {
      highlighBackdrop(encartsAccueil);

      encartCompteurs.classList.add("backdrop-between");
      document.getElementById("arrow-step12").classList.add("animate-arrow-step12");
    }
  }, 2000);

  highlighBackdrop(background);

  // Afficher l'étape 12 après l'animation de transition

  document.getElementById("button-to-step13").addEventListener("click", onClickGoToStep13);

  if (window.innerWidth < 1280) {
    setTimeout(onClickGoToStep13, 5000);
  }
}

function unsetDisplayStep12() {
  accueil.classList.add("filter-blur");

  backdrop.classList.remove("shadow-effect", "backdrop-encarts-radius");

  etapes[12].classList.add("hidden");

  encartCompteurs.classList.remove("backdrop-between");
  document.getElementById("arrow-step12").classList.remove("animate-arrow-step12");

  highlighBackdrop();

  document.getElementById("button-to-step13").removeEventListener("click", onClickGoToStep13);
}

function onClickGoToStep13() {
  unsetDisplayStep12();
  launchStep13();
}

function launchStep13() {
  saveStep = 13;
  setDisplayStep13();

  sendTagPageEtapeParcours();
}

function setDisplayStep13() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  accueil.classList.remove("filter-blur");

  backdrop.classList.add("shadow-effect", "backdrop-tutoriels-radius");

  highlighBackdrop(encartTutoriels);

  // Afficher l'étape 13 après le zoom de l'encart
  setTimeout(function () {
    if (!PAUSE) {
      etapes[13].classList.remove("hidden");
    }
  }, 3000);

  parcoursService.setFlag(false);
  parcoursService._movebackdropTo(encartTutoriels.getBoundingClientRect());
  parcoursService.animateBackdrop(encartTutoriels);

  setTimeout(function () {
    if (!PAUSE) {
      backdrop.classList.add("shadow-effect-slow");
      parcoursService.zoomTutoriel("zoom");
    }
  }, 1500);

  document.getElementById("button-to-step14").addEventListener("click", onClickGoToStep14);
}

function unsetDisplayStep13() {
  parcoursService.setFlag(true);

  accueil.classList.add("filter-blur");

  backdrop.classList.remove("shadow-effect-slow", "backdrop-tutoriels-radius");

  etapes[13].classList.add("hidden");

  parcoursService.zoomTutoriel("nozoom");

  highlighBackdrop();

  document.getElementById("button-to-step14").removeEventListener("click", onClickGoToStep14);
}

function onClickGoToStep14() {
  unsetDisplayStep13();
  launchStep14();
}

function launchStep14() {
  saveStep = 14;
  setDisplayStep14();

  sendTagPageEtapeParcours();
}

function setDisplayStep14() {
  parcoursService._moveCloseButton("steps_jaiCompris_and_afterHeader");

  backdrop.classList.add("shadow-effect-slow");

  etapes[14].classList.remove("hidden");

  highlightElement = null;
  parcoursService._movebackdropTo();

  document.getElementById("end-parcours").addEventListener("click", goToExit);
}

function unsetDisplayStep14() {
  backdrop.classList.remove("shadow-effect-slow");

  etapes[14].classList.add("hidden");

  highlighBackdrop();

  document.getElementById("end-parcours").removeEventListener("click", goToExit);
}

function goToExit() {
  unsetDisplayStep14();
  quitParcours();

  sendTagClickEtapeParcours();
}

_closeButton.addEventListener("click", () => {
  sendTagFermerParcours();

  PAUSE = true;

  _closeButton.classList.add("hidden");

  unsetStep(saveStep);

  _pageClose.classList.remove("hidden");
});

document.getElementById("mobile-exit-button").addEventListener("click", () => {
  unsetStep(saveStep);
  quitParcours();
});

document.getElementById("button-exit").onclick = function () {
  sendTagClickExitParcours();
  quitParcours();
};

document.getElementById("button-resume").onclick = function () {
  sendTagClickReprendreParcours();
  restartParcours();
};

function quitParcours() {
  unsetFrame();

  _pageClose.classList.add("hidden");
}

function unsetStep(step) {
  switch (step) {
    case 1:
      unsetDisplayStep1();
      break;

    case 2:
      unsetDisplayStep2();
      break;

    case 3:
      unsetDisplayStep3();
      break;

    case 4:
      unsetDisplayStep4();
      break;

    case 5:
      unsetDisplayStep5();
      break;

    case 6:
      unsetDisplayStep6();
      break;

    case 7:
      unsetDisplayStep7();
      break;

    case 8:
      unsetDisplayStep8();
      break;

    case 9:
      unsetDisplayStep9();
      break;

    case 10:
      unsetDisplayStep10();
      break;

    case 11:
      unsetDisplayStep11();
      break;

    case 12:
      unsetDisplayStep12();
      break;

    case 13:
      unsetDisplayStep13();
      break;

    case 14:
      unsetDisplayStep14();
      break;
  }
}

function restartParcours() {
  switch (saveStep) {
    case 1:
      launchStep1();
      break;

    case 2:
      launchStep2();
      break;

    case 3:
      launchStep3();
      break;

    case 4:
      launchStep4();
      break;

    case 5:
      launchStep5();
      break;

    case 6:
      launchStep6();
      break;

    case 7:
      launchStep7();
      break;

    case 8:
      launchStep8();
      break;

    case 9:
      launchStep9();
      break;

    case 10:
      launchStep10();
      break;

    case 11:
      launchStep11();
      break;

    case 12:
      launchStep12();
      break;

    case 13:
      launchStep13();
      break;

    case 14:
      launchStep14();
      break;
  }

  _closeButton.classList.remove("hidden");
  _pageClose.classList.add("hidden");

  PAUSE = false;
}

function onResizeWindowBackdropAction() {
  let width = this.window.innerWidth;

  if (width < 768) {
    if (!parcoursService.flag) {
      parcoursService.setFlag(true);
    }
    highlighBackdrop();
  } else if (width > 768 && !PAUSE && highlightElement) {
    resizeBackdrop();
  }
}

function highlighBackdrop(element) {
  if (element) {
    highlightElement = element;
    parcoursService._movebackdropTo(highlightElement.getBoundingClientRect());
  } else {
    parcoursService._movebackdropTo();
  }
}

function resizeBackdrop() {
  backdrop.classList.remove("shadow-effect");

  const position = highlightElement.getBoundingClientRect();

  if (
    backdrop.style.top !== position.top &&
    backdrop.style.left !== position.left &&
    backdrop.style.bottom !== position.bottom &&
    backdrop.style.right !== position.right &&
    backdrop.style.width !== position.width &&
    backdrop.style.height !== position.height
  ) {
    parcoursService._movebackdropTo(position);
  }
}

// TAG COMMANDER
function sendTagClickParcours() {
  TagCommanderManager.tagClick(CLICK_LANCER_PARCOURS);
  sendTagPageParcours();
}

function sendTagPageParcours() {
  TagCommanderManager.tagPage(PARCOURS_GUIDE);
}

function sendTagPageEtapeParcours() {
  numMenu++;
  if (saveStep === 2) {
    TagCommanderManager.tagPage(PAGE_CLICK_GESTION_DE_VOTRE_ENTREPRISE);
  } else if (saveStep === 4) {
    TagCommanderManager.tagPage(PAGE_CLICK_VOS_DONNEES_ENERGETIQUES);
  } else if (saveStep === 6) {
    TagCommanderManager.tagPage(PAGE_CLICK_VOS_DOCUMENTS);
  } else if (saveStep === 8) {
    TagCommanderManager.tagPage(PAGE_CLICK_VOS_PROJETS_RACCORDEMENT);
  } else if (saveStep === 10) {
    TagCommanderManager.tagPage(PAGE_CLICK_GESTION_DE_COMPTE);
  } else if (saveStep === 12) {
    TagCommanderManager.tagPage(PAGE_FONCTIONNALITES);
  } else if (saveStep === 13) {
    TagCommanderManager.tagPage(PAGE_GUIDES_UTILISATEURS);
  } else if (saveStep === 14) {
    TagCommanderManager.tagPage(PAGE_FIN_PARCOURS);
  }
}

function sendTagClickEtapeParcours() {
  if (saveStep === 3) {
    TagCommanderManager.tagClick(CLICK_GESTION_DE_VOTRE_ENTREPRISE);
  } else if (saveStep === 5) {
    TagCommanderManager.tagClick(CLICK_VOS_DONNEES_ENERGETIQUES);
  } else if (saveStep === 7) {
    TagCommanderManager.tagClick(CLICK_VOS_DOCUMENTS);
  } else if (saveStep === 9) {
    TagCommanderManager.tagClick(CLICK_VOS_PROJETS_RACCORDEMENT);
  } else if (saveStep === 11) {
    TagCommanderManager.tagClick(CLICK_GESTION_DE_COMPTE);
  } else if (saveStep === 14) {
    numMenu++;
    TagCommanderManager.tagClick(CLICK_FIN_PARCOURS);
  }
}

function sendTagClickReprendreParcours() {
  TagCommanderManager.tagClick(CLICK_REPRENDRE_LE_TUTORIEL);
}

function sendTagFermerParcours() {
  TagCommanderManager.tagClick(FERMETURE_PARCOURS(numMenu));
  TagCommanderManager.tagPage(PAGE_FERMETURE_DU_PARCOURS_GUIDE);
}

function sendTagClickExitParcours() {
  TagCommanderManager.tagClick(EXIT_PARCOURS(numMenu));
}
